/* You can add global styles to this file, and also import other style files */
@import '@ionic/angular/css/core.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'assets/css/vip_icons.css';
@import '../../../libs/utils/styles/global.scss';
@import '../../../libs/utils/styles/backdrop.scss';

html,
body {
  height: 100%;
}

.vip-page-content {
  @apply flex flex-col flex-grow;
  height: 100%;
  background-color: var(--vip-color-app-background-main);
  overflow: auto;
  position: relative;
}
