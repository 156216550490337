$animationTime: 0.5s;

/*
*  Para criar um backdrop, utilizar a classe 'vip-common-backdrop'. Para exibir o backdrop, incluir a classe 'show'.
*
*  Exemplos:
*  1) Backdrop oculto
*
*   <div class="vip-common-backdrop">Meu Conteúdo</div>
*
*  2) Backdrop visível
*
*   <div class="vip-common-backdrop show">Meu Conteúdo</div>
*/

.vip-common-backdrop {
  z-index: 999999999;
  position: fixed;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;

  &.bottom {
    justify-content: end;
  }

  &.show {
    transition: all $animationTime ease-in, width 0s linear, height 0s linear;
    background-color: #0000005e;

    &.transparent {
      background-color: transparent;
    }

    &.white {
      background-color: #fff;
    }
  }

  &:not(.show) {
    width: 0;
    height: 0;
    overflow: hidden;
    transition: all $animationTime ease-out, width 0s linear $animationTime, height 0s linear $animationTime;
    background-color: transparent;
  }
}
