.vip-divider {
  border-top: 1px solid var(--vip-component-divider-color-background);

  &.primary {
    border-color: var(--vip-color-primary-main);
  }

  &.secondary {
    border-color: var(--vip-color-secondary-main);
  }
}

.vip-divider-dashed {
  border-top: 1px dashed var(--vip-component-divider-color-background);

  &.primary {
    border-color: var(--vip-color-primary-main);
  }

  &.secondary {
    border-color: var(--vip-color-secondary-main);
  }
}

.vip-divider-vertical {
  border-right: 1px solid var(--vip-component-divider-color-background);
  height: auto;

  &.primary {
    border-color: var(--vip-color-primary-main);
  }

  &.secondary {
    border-color: var(--vip-color-secondary-main);
  }
}

.vip-color-disabled {
  color: var(--vip-color-disabled-main);
}

.vip-color-first-default {
  color: var(--vip-color-first-default-main);
}

.vip-color-secondary-default {
  color: var(--vip-color-secondary-default-main);
}

.vip-color-third-default {
  color: var(--vip-color-third-default-main);
}

.vip-color-primary-main {
  color: var(--vip-color-primary-main);
}

.vip-color-primary-background {
  background: var(--vip-color-primary-main);
}

.vip-color-primary-contrast {
  color: var(--vip-color-primary-contrast);
}

.vip-color-secondary-main {
  color: var(--vip-color-secondary-main);
}

.vip-color-success-main {
  color: var(--vip-color-success-main);
}

.vip-color-error-main {
  color: var(--vip-color-error-main);
}

.vip-color-error-background {
  background: var(--vip-color-error-main);
}


.vip-color-error-contrast {
  color: var(--vip-color-error-contrast);
}

.vip-color-complementary-main {
  color: var(--vip-color-complementary-main);
}

@mixin background($property) {
  &,
  &-main {
    background-color: var(--vip-color-#{$property}-main);

    &.hoverable:hover,
    &.clickable:hover {
      background-color: var(--vip-color-#{$property}-darken);
      cursor: pointer;
    }
  }
}


.preco-vip {
  color: var(--vip-tag-ofertas-cor-background);
}

.mais-por-menos {
  color: var(--vip-tag-mais-por-menos-cor-background);
}

.combo-vip {
  color: var(--vip-tag-combos-cor-background);
}

.leve-e-pague {
  color: var(--vip-tag-leve-e-pague-cor-background);
}

.oferta-atacarejo {
  color: var(--vip-tag-atacarejo-cor-background);
}

.oferta-exclusiva {
  color: var(--vip-tag-oferta-exclusiva-cor-background);
}

.oferta-vermelho {
  color: var(--vip-tag-oferta-vermelho-background);
}

.oferta-preto {
  color: var(--vip-tag-oferta-preto-background);
}

.oferta-laranja {
  color: var(--vip-tag-oferta-laranja-background);
}

.vip-background-app,
.vip-background-app-background {
  @include background(app-background);
}

.vip-background-complementary {
  @include background(complementary);
}

.vip-background-complementary-rounded {
  border-radius: 1.5rem;
}

.vip-background-fourth-default {
  @include background(fourth-default);
}

.vip-background-third-default {
  @include background(third-default);
}

.vip-background-secondary-default {
  @include background(secondary-default);
}

.vip-background-first-default {
  @include background(first-default);
}

.vip-background-secondary {
  @include background(secondary);
}

.vip-background-primary {
  @include background(primary);
}

.vip-background-success {
  @include background(success);
}

.vip-background-error {
  @include background(error);
}

.vip-border{
  border: 1px solid var(--vip-color-fourth-default-main);
}

.vip-text-ellipsis{
  @apply max-w-full overflow-x-hidden text-ellipsis whitespace-nowrap;
}

.vip-dropdown{
  @apply p-3 text-xs rounded shadow-sm;
  z-index: 99999;
  background-color: var(--vip-color-app-background-main);

  &.vip-infinite {
    @apply p-0 ;
  }
}
