@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-access_time:before {
  content: '\e918';
}
.icon-account_circle:before {
  content: '\e94b';
}
.icon-add:before {
  content: '\e90e';
}
.icon-add_comment:before {
  content: '\e901';
}
.icon-add_shopping_cart:before {
  content: '\e902';
}
.icon-alarm:before {
  content: '\e912';
}
.icon-apps:before {
  content: '\e93d';
}
.icon-arrow_back:before {
  content: '\e936';
}
.icon-attach_money:before {
  content: '\e903';
}
.icon-backspace:before {
  content: '\e945';
}
.icon-bar_code:before {
  content: '\e904';
}
.icon-block:before {
  content: '\e900';
}
.icon-brightness:before {
  content: '\e90a';
}
.icon-business_center:before {
  content: '\e927';
}
.icon-calendar_today:before {
  content: '\e91a';
}
.icon-card_membership:before {
  content: '\e905';
}
.icon-cashback:before {
  content: '\e906';
}
.icon-check:before {
  content: '\e91c';
}
.icon-check_box:before {
  content: '\e911';
}
.icon-check_box_outline_blank:before {
  content: '\e910';
}
.icon-check_circle:before {
  content: '\e90d';
}
.icon-check_circle_filled:before {
  content: '\e907';
}
.icon-chevron_left:before {
  content: '\e941';
}
.icon-chevron_right:before {
  content: '\e939';
}
.icon-close:before {
  content: '\e917';
}
.icon-compare_arrows:before {
  content: '\e95b';
}
.icon-confirmation_number:before {
  content: '\e908';
}
.icon-copy:before {
  content: '\e95f';
}
.icon-credit_card:before {
  content: '\e93a';
}
.icon-delete_forever:before {
  content: '\e915';
}
.icon-delete_outline:before {
  content: '\e909';
}
.icon-description:before {
  content: '\e90b';
}
.icon-devolucao:before {
  content: '\e90c';
}
.icon-directions_walk:before {
  content: '\e93e';
}
.icon-discount_price:before {
  content: '\e94f';
}
.icon-edit:before {
  content: '\e929';
}
.icon-error_filled:before {
  content: '\e961';
}
.icon-error_outline:before {
  content: '\e90f';
}
.icon-expand_more:before {
  content: '\e948';
}
.icon-fastfood:before {
  content: '\e953';
}
.icon-favorite:before {
  content: '\e924';
}
.icon-favorite_border:before {
  content: '\e925';
}
.icon-filter_list:before {
  content: '\e913';
}
.icon-format_list_bulleted:before {
  content: '\e931';
}
.icon-free_breakfast:before {
  content: '\e949';
}
.icon-gem:before {
  content: '\e914';
}
.icon-gps_fixed:before {
  content: '\e938';
}
.icon-grocery:before {
  content: '\e916';
}
.icon-grocery_filled:before {
  content: '\e919';
}
.icon-hand_holding_heart:before {
  content: '\e91b';
}
.icon-home:before {
  content: '\e965';
}
.icon-home1:before {
  content: '\e946';
}
.icon-home_filled:before {
  content: '\e966';
}
.icon-house:before {
  content: '\e95c';
}
.icon-inbox:before {
  content: '\e920';
}
.icon-info:before {
  content: '\e921';
}
.icon-info_filled:before {
  content: '\e91d';
}
.icon-insert_drive_file:before {
  content: '\e91e';
}
.icon-insert_invitation:before {
  content: '\e947';
}
.icon-launch:before {
  content: '\e91f';
}
.icon-list_alt:before {
  content: '\e926';
}
.icon-local_activity:before {
  content: '\e922';
}
.icon-local_dining:before {
  content: '\e943';
}
.icon-local_mall:before {
  content: '\e93f';
}
.icon-local_offer:before {
  content: '\e923';
}
.icon-local_shipping:before {
  content: '\e92a';
}
.icon-location_on:before {
  content: '\e928';
}
.icon-lock:before {
  content: '\e940';
}
.icon-mail:before {
  content: '\e92b';
}
.icon-menu:before {
  content: '\e92d';
}
.icon-message:before {
  content: '\e944';
}
.icon-minimize:before {
  content: '\e935';
}
.icon-monetization_on:before {
  content: '\e92c';
}
.icon-more_vert:before {
  content: '\e92e';
}
.icon-notifications:before {
  content: '\e942';
}
.icon-package:before {
  content: '\e92f';
}
.icon-people:before {
  content: '\e930';
}
.icon-person:before {
  content: '\e94a';
}
.icon-phone:before {
  content: '\e937';
}
.icon-phone_iphone:before {
  content: '\e93c';
}
.icon-pix:before {
  content: '\e932';
}
.icon-play_circle_outline:before {
  content: '\e933';
}
.icon-playlist_add:before {
  content: '\e93b';
}
.icon-price-tag:before {
  content: '\e959';
}
.icon-qr_code_scanner:before {
  content: '\e934';
}
.icon-receipt:before {
  content: '\e94c';
}
.icon-remove_shopping_cart:before {
  content: '\e94d';
}
.icon-search:before {
  content: '\e94e';
}
.icon-sell:before {
  content: '\e967';
}
.icon-sell_filled:before {
  content: '\e968';
}
.icon-share:before {
  content: '\e950';
}
.icon-shopping_basket:before {
  content: '\e969';
}
.icon-shopping_basket1:before {
  content: '\e951';
}
.icon-shopping_basket_filled:before {
  content: '\e96a';
}
.icon-shopping_basket_open:before {
  content: '\e952';
}
.icon-shopping_cart:before {
  content: '\e954';
}
.icon-spa:before {
  content: '\e955';
}
.icon-star:before {
  content: '\e956';
}
.icon-star_border:before {
  content: '\e957';
}
.icon-star_half:before {
  content: '\e958';
}
.icon-store_mall:before {
  content: '\e95a';
}
.icon-supervisor_account:before {
  content: '\e95d';
}
.icon-troca:before {
  content: '\e95e';
}
.icon-und:before {
  content: '\e960';
}
.icon-update:before {
  content: '\e962';
}
.icon-visibility_off:before {
  content: '\e963';
}
.icon-visibility_on:before {
  content: '\e964';
}
.icon-weight:before {
  content: '\e96b';
}
.icon-whatsapp:before {
  content: '\e96c';
}
.icon-widgets:before {
  content: '\e96d';
}
.icon-widgets_filled:before {
  content: '\e96e';
}
.icon-work_outline:before {
  content: '\e96f';
}
